@import '~app/mixins';

$searchResultsWidth: 570px;
$statisticsHeight: 180px;

.wrapper {
  position: absolute;
  width: calc(100vw - 0px);
  height: 100%;
  flex-direction: column;
  display: flex;
  padding-left: 50px;

  .content {
    position: relative;
    flex: 1;
    z-index: 1;

    &Overlayed {
      &:before {
        content: " ";
        z-index: 10;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(192, 192, 192, 0.64);
      }
    }

    .leftSide {
      width: calc(100% - #{$searchResultsWidth});
      background-color: #c3deff;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      &.leftSideFullSize {
        width: 100%;
      }

      .map {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(100% - #{$statisticsHeight});

        &.mapFullSize {
          height: 100%;
        }
      }

      .statistics {
        position: absolute;
        height: $statisticsHeight;
        bottom: 0;
        width: 100%;
        background-color: $color-dirty-white;

        @include transition(height);

        .bottomExpand {
          position: absolute;
          left: 50%;
          margin-left: -28px;
          top: -21px;
          height: 21px;
          width: 56px;
          background-color: $color-white;
          box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.37);
          border: 0;
          padding: 0;
          cursor: pointer;

          .iconCaret {
            width: 14px;
            height: 8px;
            transform: rotate(180deg);
          }
        }

        &Expanded {
          height: calc(100% - 90px);
          z-index: 11;

          .bottomExpand {
            .iconCaret {
              transform: none;
            }
          }

          :global {
            section {
              height: 100%;
              overflow-x: hidden;
              overflow-y: auto;
            }
          }
        }
      }
    }

    .rightSide {
      width: $searchResultsWidth;
      background-color: $color-white;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      @include transition(width);

      .rightExpand {
        position: absolute;
        top: 50%;
        margin-top: -28px;
        left: -21px;
        height: 56px;
        width: 21px;
        background-color: $color-white;
        box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.37);
        border: 0;
        border-radius: 4px 0 0 4px;
        padding: 0;
        cursor: pointer;

        .iconCaret {
          width: 14px;
          height: 8px;
          transform: rotate(90deg);
        }
      }

      &Expanded {
        width: 98%;
        z-index: 100;

        .rightExpand {
          .iconCaret {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .resultsHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 12px 8px;
      border: 1px solid #eee;
      background-color: $color-white;
      position: relative;

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .left {
        .caption {
          margin-left: 6px;
          font-size: 16px;
          color: $color-blue-dark;
        }

        .selected {
          margin-left: 7px;
          font-size: 11px;
          font-style: italic;
          color: #6b7581;
        }
      }

      .right {
        button {
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
